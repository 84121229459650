import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional Theme applied to the Data Grid

import { CellEditingStoppedEvent, ColDef, IRowNode } from 'ag-grid-community'; // Import the ColDef type from ag-grid
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import { AdminPrivateRoutes } from 'config';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PAYCODETYPES } from 'utils/Constant';

export interface PayrollTableProps {
  summaryTableData: any;
  handleRowEdit: (row: CellEditingStoppedEvent<any, any>) => void;
  hiddenColumns: { label: string; value: string }[];
}

export const tableCols: ColDef[] = [
  {
    cellRenderer: 'agCheckboxCellRenderer',
    headerName: '',
    pinned: 'left',
    resizable: false,
    showDisabledCheckboxes: true,
    type: 'shaded',
    valueGetter: (row) => {
      if (row.data?.['dividerRow'] === true) return;
      return row.data?.['summary_isVerified'] === true ? true : false;
    },
    valueSetter: (row) => {
      if (row.data?.['dividerRow'] === true) return false;
      row.data['summary_isVerified'] = row.newValue;
      if (row.newValue === true) {
        const selectedNodes = row.api.getSelectedNodes();
        selectedNodes.push(row.node);
        row.api.setNodesSelected({
          newValue: row.newValue,
          nodes: selectedNodes,
        });
      } else {
        const selectedNodes = row.api.getSelectedNodes();
        const index = selectedNodes.findIndex((node) => node === row.node);
        selectedNodes.splice(index, 1);
        row.api.deselectAll();
        row.api.setNodesSelected({ newValue: true, nodes: selectedNodes });
      }
      return true;
    },
    width: 50,
  },
  {
    editable: false,
    field: 'user_workAuth',
    filter: 'agSetColumnFilter',
    headerName: 'Work Auth',
    pinned: 'left',
    type: 'shaded',
  },
  {
    cellClass: 'clickable-cell shaded-cell',
    cellRenderer: '',
    editable: false,
    field: 'user_name',
    filter: 'agSetColumnFilter',
    filterParams: {
      applyMiniFilterWhileTyping: true,
    },
    headerName: 'Name',
    onCellClicked: (e) => {
      window.location.href =
        AdminPrivateRoutes.CONSULTANTS + '/profile/' + e.data.user_id;
    },
    pinned: 'left',
    type: 'shaded',
    valueGetter: (row) => {
      if (row.data?.['dividerRow'] === true) {
        return row.data['name'];
      }
      return row.data['user_lastName'] + ', ' + row.data['user_firstName'];
    },
    width: 200,
  },
  {
    editable: false,
    field: 'user_jobWcCode',
    filter: 'agSetColumnFilter',
    headerName: 'Pay Code',
    pinned: 'left',
    type: 'shaded',
  },
  {
    editable: false,
    field: 'user_consultantStatus',
    headerName: 'Employment Status',
    width: 150,
  },
  {
    autoHeight: true,
    // cellEditor: SimpleTextEditor,
    field: 'summary_notes',
    headerName: 'Notes',
    width: 300,
    wrapText: true,
  },
  {
    editable: false,
    field: 'summary_baseWage',
    headerName: 'Base Wage',
    type: 'currency',
  },
  {
    editable: false,
    field: 'summary_basePay',
    headerName: 'Base',
    type: 'currency',
    valueGetter: (row) => {
      if (row.data['summary_jobWcCode'] === PAYCODETYPES.HOURLY.id)
        return row.data['summary_payRate'];

      return row.data['summary_basePay'];
    },
  },
  { editable: false, field: 'user_companyCode', headerName: 'Co Code' },
  { editable: false, field: 'summary_batchId', headerName: 'Batch ID' },
  { editable: false, field: 'user_empCode', headerName: 'File #' },
  {
    field: 'summary_regHours',
    headerName: 'Reg Hours',
    valueGetter: (row) =>
      row.data['summary_regHours_orginal']
        ? row.data['summary_regHours_orginal']
        : row.data['summary_regHours'],
  },
  {
    field: 'summary_grossEarnings',
    headerName: 'Gross Earnings',
    type: 'currency',
    valueGetter: (row) =>
      row.data['summary_grossEarnings_realTime']
        ? row.data['summary_grossEarnings_realTime']
        : row.data['summary_grossEarnings'],
  },
  {
    editable: (row) =>
      row.data['summary_jobWcCode'] === PAYCODETYPES.HOURLY.id ||
      row.data['summary_jobWcCode'] === PAYCODETYPES.SALARY.id
        ? false
        : true,
    field: 'summary_regEarnings',
    headerName: 'Reg Earnings',
    type: 'currency',
    valueGetter: (row) => row.data['summary_regEarnings'],
    valueSetter: (row) => {
      const item = row.data;
      item['summary_regEarnings'] = row.newValue;
      item['summary_grossEarnings_realTime'] = Number(
        Number(
          item.summary_currentEarnings3Amount1
            ? item.summary_currentEarnings3Amount1
            : 0,
        ) +
          Number(
            row.newValue ? row.newValue.replace('$', '').replace(',', '') : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount6
              ? item.summary_currentEarnings3Amount6
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount8
              ? item.summary_currentEarnings3Amount8
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount2
              ? item.summary_currentEarnings3Amount2
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount5
              ? item.summary_currentEarnings3Amount5
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount9
              ? item.summary_currentEarnings3Amount9
              : 0,
          ),
      );
      return true;
    },
  },
  {
    editable: true,
    field: 'summary_currentEarnings3Code1',
    headerName: 'Earnings 3 Code',
  },
  {
    field: 'summary_currentEarnings3Amount1',
    headerName: 'Earnings 3 Amount',
    type: 'currency',
    valueSetter: (row) => {
      const item = row.data;
      item['summary_currentEarnings3Amount1'] = row.newValue;
      item['summary_grossEarnings_realTime'] = Number(
        Number(
          row.newValue ? row.newValue.replace('$', '').replace(',', '') : 0,
        ) +
          Number(item.summary_regEarnings ? item.summary_regEarnings : 0) +
          Number(
            item.summary_currentEarnings3Amount6
              ? item.summary_currentEarnings3Amount6
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount8
              ? item.summary_currentEarnings3Amount8
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount2
              ? item.summary_currentEarnings3Amount2
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount5
              ? item.summary_currentEarnings3Amount5
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount9
              ? item.summary_currentEarnings3Amount9
              : 0,
          ),
      );
      return true;
    },
  },
  {
    field: 'summary_currentHours3Code1',
    headerName: 'Hours 3 Code',
  },
  {
    editable: (row) => {
      return (
        row.data['summary_jobWcCode'] ===
          PAYCODETYPES.PIECEWORK_INDEPENDENT.id &&
        row.data['user_consultantStatus'].toLowerCase() !== 'terminated'
      );
    },
    field: 'summary_currentHours3Amount1',
    headerName: 'Hours 3 Amount',
    valueGetter: (row) =>
      row.data['summary_jobWcCode'] === PAYCODETYPES.HOURLY.id ||
      row.data['summary_jobWcCode'] === PAYCODETYPES.SALARY.id
        ? ''
        : row.data['summary_currentHours3Amount1'],
    valueSetter: (row) => {
      const item = row.data;
      item['summary_currentHours3Amount1'] = row.newValue;
      item['summary_currentEarnings3Amount2'] = Number(
        (Number(item.user_baseWage) / 2080) * Number(row.newValue),
      );
      item['summary_regEarnings'] = Number(
        Number(item.user_basePay) -
          (Number(item.user_baseWage) / 2080) * Number(row.newValue) -
          (Number(item.user_baseWage) / 2080) *
            Number(item['summary_currentHours3Amount2']),
      );
      return true;
    },
  },
  {
    field: 'summary_currentEarnings3Code2',
    headerName: 'Earnings 3 Code',
  },
  {
    field: 'summary_currentEarnings3Amount2',
    headerName: 'Earnings 3 Amount',
    type: 'currency',
    valueGetter: (row) => row.data['summary_currentEarnings3Amount2'],
  },
  {
    field: 'summary_currentHours3Code2',
    headerName: 'Hours 3 Code',
  },
  {
    field: 'summary_currentHours3Amount2',
    headerName: 'Hours 3 Amount',
    valueGetter: (row) => row.data['summary_currentHours3Amount2'],
    valueSetter: (row) => {
      const item = row.data;
      item['summary_currentHours3Amount2'] = row.newValue;
      if (
        item['user_consultantStatus'].toLowerCase() !== 'terminated' &&
        item['summary_jobWcCode'] === PAYCODETYPES.PIECEWORK_INDEPENDENT.id
      ) {
        item['summary_currentEarnings3Amount3'] =
          (Number(item['user_baseWage']) / 2080) * Number(row.newValue);
        item['summary_regEarnings'] =
          Number(item['user_basePay']) -
          (Number(item['user_baseWage']) / 2080) *
            Number(item['summary_currentHours3Amount1']) -
          (Number(item['user_baseWage']) / 2080) * Number(row.newValue);
      }
      if (
        item.summary_jobWcCode === PAYCODETYPES.HOURLY.id ||
        item.summary_jobWcCode === PAYCODETYPES.SALARY.id
      ) {
        item['summary_currentEarnings3Amount3'] = Number(
          Number(
            row.newValue ? Number(row.newValue.replace(/[^0-9.-]+/g, '')) : 0,
          ) * Number(item.summary_payRate ? item.summary_payRate : 0),
        ).toFixed(2);
      }
      item['summary_regHours_original'] = (
        Number(item['summary_regHours']) - Number(row.newValue)
      ).toFixed(2);
      return true;
    },
  },
  {
    field: 'summary_currentEarnings3Code3',
    headerName: 'Earnings 3 Code',
  },
  {
    field: 'summary_currentEarnings3Amount3',
    headerName: 'Earnings 3 Amount',
    type: 'currency',
    valueGetter: (row) => row.data['summary_currentEarnings3Amount3'],
  },
  {
    field: 'summary_currentEarnings3Code4',
    headerName: 'Earnings 3 Code',
  },
  {
    field: 'summary_currentEarnings3Amount4',
    headerName: 'Earnings 3 Amount',
    type: 'currency',
    valueGetter: (row) => row.data['summary_currentEarnings3Amount4'],
  },
  {
    field: 'summary_currentEarnings3Code5',
    headerName: 'Earnings 3 Code',
  },
  {
    field: 'summary_currentEarnings3Amount5',
    headerName: 'Earnings 3 Amount',
    type: 'currency',
    valueGetter: (row) => row.data['summary_currentEarnings3Amount5'],
    valueSetter: (row) => {
      const item = row.data;
      item['summary_currentEarnings3Amount5'] = row.newValue;
      item['summary_grossEarnings_realTime'] = Number(
        Number(
          item.summary_currentEarnings3Amount1
            ? item.summary_currentEarnings3Amount1
            : 0,
        ) +
          Number(item.summary_regEarnings ? item.summary_regEarnings : 0) +
          Number(
            row.newValue ? row.newValue.replace('$', '').replace(',', '') : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount8
              ? item.summary_currentEarnings3Amount8
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount2
              ? item.summary_currentEarnings3Amount2
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount6
              ? item.summary_currentEarnings3Amount6
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount9
              ? item.summary_currentEarnings3Amount9
              : 0,
          ),
      );

      return true;
    },
  },
  {
    field: 'summary_currentEarnings3Code6',
    headerName: 'Earnings 3 Code',
  },
  {
    field: 'summary_currentEarnings3Amount6',
    headerName: 'Earnings 3 Amount',
    type: 'currency',
    valueGetter: (row) => row.data['summary_currentEarnings3Amount6'],
    valueSetter: (row) => {
      const item = row.data;
      item['summary_currentEarnings3Amount6'] = row.newValue;
      item['summary_grossEarnings_realTime'] = Number(
        Number(
          item.summary_currentEarnings3Amount1
            ? item.summary_currentEarnings3Amount1
            : 0,
        ) +
          Number(item.summary_regEarnings ? item.summary_regEarnings : 0) +
          Number(
            row.newValue ? row.newValue.replace('$', '').replace(',', '') : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount8
              ? item.summary_currentEarnings3Amount8
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount2
              ? item.summary_currentEarnings3Amount2
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount5
              ? item.summary_currentEarnings3Amount5
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount9
              ? item.summary_currentEarnings3Amount9
              : 0,
          ),
      );
      return true;
    },
  },
  {
    field: 'summary_currentHours3Code3',
    headerName: 'Hours 3 Code',
  },
  {
    field: 'summary_currentHours3Amount3',
    headerName: 'Hours 3 Amount',
    type: 'currency',
    valueGetter: (row) => row.data['summary_currentHours3Amount3'],
  },
  {
    field: 'summary_currentEarnings3Code7',
    headerName: 'Earnings 3 Code',
  },
  {
    field: 'summary_currentEarnings3Amount7',
    headerName: 'Earnings 3 Amount',
    type: 'currency',
    valueGetter: (row) => row.data['summary_currentEarnings3Amount7'],
  },
  {
    field: 'summary_currentEarnings3Code8',
    headerName: 'Earnings 3 Code',
  },
  {
    field: 'summary_currentEarnings3Amount8',
    headerName: 'Earnings 3 Amount',
    type: 'currency',
    valueGetter: (row) => row.data['summary_currentEarnings3Amount8'],
    valueSetter: (row) => {
      const item = row.data;
      item['summary_currentEarnings3Amount8'] = row.newValue;
      item['summary_grossEarnings_realTime'] = Number(
        Number(
          item.summary_currentEarnings3Amount1
            ? item.summary_currentEarnings3Amount1
            : 0,
        ) +
          Number(item.summary_regEarnings ? item.summary_regEarnings : 0) +
          Number(
            item.summary_currentEarnings3Amount6
              ? item.summary_currentEarnings3Amount6
              : 0,
          ) +
          Number(
            row.newValue ? row.newValue.replace('$', '').replace(',', '') : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount2
              ? item.summary_currentEarnings3Amount2
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount5
              ? item.summary_currentEarnings3Amount5
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount9
              ? item.summary_currentEarnings3Amount9
              : 0,
          ),
      );
      return true;
    },
  },
  {
    field: 'summary_currentEarnings3Code9',
    headerName: 'Earnings 3 Code',
  },
  {
    field: 'summary_currentEarnings3Amount9',
    headerName: 'Earnings 3 Amount',
    type: 'currency',
    valueGetter: (row) => row.data['summary_currentEarnings3Amount9'],
    valueSetter: (row) => {
      const item = row.data;
      item['summary_currentEarnings3Amount9'] = row.newValue;
      item['summary_grossEarnings_realTime'] = Number(
        Number(
          item.summary_currentEarnings3Amount1
            ? item.summary_currentEarnings3Amount1
            : 0,
        ) +
          Number(item.summary_regEarnings ? item.summary_regEarnings : 0) +
          Number(
            row.newValue ? row.newValue.replace('$', '').replace(',', '') : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount8
              ? item.summary_currentEarnings3Amount8
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount2
              ? item.summary_currentEarnings3Amount2
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount5
              ? item.summary_currentEarnings3Amount5
              : 0,
          ) +
          Number(
            item.summary_currentEarnings3Amount6
              ? item.summary_currentEarnings3Amount6
              : 0,
          ),
      );
      return true;
    },
  },
  {
    field: 'summary_currentAdjustDedCode1',
    headerName: 'Adjust DED Code',
  },
  {
    field: 'summary_currentAdjustDedAmount1',
    headerName: 'Adjust DED Amount',
  },
  {
    field: 'summary_currentAdjustDedCode2',
    headerName: 'Adjust DED Code',
  },
  {
    field: 'summary_currentAdjustDedAmount2',
    headerName: 'Adjust DED Amount',
  },
  {
    field: 'summary_currentAdjustDedCode3',
    headerName: 'Adjust DED Code',
  },
  {
    field: 'summary_currentAdjustDedAmount3',
    headerName: 'Adjust DED Amount',
  },
  {
    field: 'summary_currentAdjustDedCode4',
    headerName: 'Adjust DED Code',
  },
  {
    field: 'summary_currentAdjustDedAmount4',
    headerName: 'Adjust DED Amount',
  },
  {
    field: 'summary_currentAdjustDedCode5',
    headerName: 'Adjust DED Code',
  },
  {
    field: 'summary_currentAdjustDedAmount5',
    headerName: 'Adjust DED Amount',
  },
  {
    field: 'summary_currentAdjustDedCode6',
    headerName: 'Adjust DED Code',
  },
  {
    field: 'summary_currentAdjustDedAmount6',
    headerName: 'Adjust DED Amount',
  },
  {
    field: 'summary_currentAdjustDedCode7',
    headerName: 'Adjust DED Code',
  },
  {
    field: 'summary_currentAdjustDedAmount7',
    headerName: 'Adjust DED Amount',
  },
  {
    field: 'summary_currentAdjustDedCode8',
    headerName: 'Adjust DED Code',
  },
  {
    field: 'summary_currentAdjustDedAmount8',
    headerName: 'Adjust DED Amount',
  },
  {
    field: 'summary_psAdjustedAmount',
    headerName: 'Adjusted Hour',
    valueSetter: (row) => {
      const item = row.data;
      item['summary_psAdjustedAmount'] = row.newValue;
      item['summary_regHours_orginal'] = Number(
        Number(item.summary_regHours) + Number(row.newValue),
      ).toFixed(2);
      return true;
    },
  },
];

export default function PayrollTable({
  summaryTableData,
  handleRowEdit,
  hiddenColumns,
}: PayrollTableProps) {
  const columnTypes = useMemo(() => {
    return {
      currency: {
        valueFormatter: (cell) => {
          return isNaN(cell.value)
            ? ''
            : Number(cell.value).toLocaleString('en-US', {
                currency: 'USD',
                style: 'currency',
              });
        },
        width: 150,
      },
      shaded: {
        cellClass: 'shaded-cell',
      },
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [defaultColDef, setDefaultColDef] = useState<ColDef>({
    editable: true,
    hide: false,
    width: 100,
  });

  const dataWithDoNotPayRow = () => {
    // add row above summary row if summary.donotpay is true
    const index = summaryTableData.findIndex((item) => item.summary_doNotPay);
    if (index >= 0) {
      const doNotPayRow = { dividerRow: true, name: 'DO NOT PAY' };
      summaryTableData.splice(index, 0, doNotPayRow);
    }
    return summaryTableData;
  };

  const [colDefs, setColDefs] = useState<ColDef[]>(tableCols);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rowData, setRowData] = useState<any[]>(dataWithDoNotPayRow());

  useEffect(() => {
    setColDefs((prev) => {
      return prev.map((col) => {
        if (hiddenColumns?.find((item) => item.value === col.field)) {
          return { ...col, hide: true };
        }
        return col;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenColumns]);

  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-alpine" // applying the Data Grid theme
      style={{ height: 500 }} // the Data Grid will fill the size of the parent container
    >
      <AgGridReact
        onRowValueChanged={(e) => {
          // console.log('row change', e);
        }}
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        columnTypes={columnTypes}
        suppressMovableColumns={true}
        suppressDragLeaveHidesColumns={true}
        onCellEditingStopped={(e) => {
          if (e.data?.['dividerRow'] === true) return;
          handleRowEdit(e);
        }}
        rowSelection="multiple"
        onRowSelected={(e) => {
          // console.log('row selected', e);
        }}
        suppressRowClickSelection={true}
        onFirstDataRendered={(row) => {
          const nodesToSelect: IRowNode[] = [];
          row.api.forEachNode((node) => {
            if (node.data.summary_isVerified) {
              nodesToSelect.push(node);
            }
          });
          row.api.setNodesSelected({ newValue: true, nodes: nodesToSelect });
        }}
      />
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SimpleTextEditor(
  { value, onValueChange, eventKey, rowIndex, column },
  ref,
) {
  const updateValue = (val) => {
    console.log('updateValue', val);
    onValueChange(val === '' ? null : val);
  };

  useEffect(() => {
    let startValue;

    if (eventKey === 'Backspace') {
      startValue = '';
    } else if (eventKey && eventKey.length === 1) {
      startValue = eventKey;
    } else {
      startValue = value;
    }
    if (startValue == null) {
      startValue = '';
    }

    updateValue(startValue);

    refInput.current.focus();
  });
  const refInput = useRef(null);

  return (
    <input
      value={value || ''}
      ref={refInput}
      onChange={(event) => updateValue(event.target.value)}
      style={{ height: '100%', width: '100%' }}
    />
  );
}
